import Vue from "vue";
import Router from "vue-router";
Vue.use(Router);
let router = new Router({
    mode: "history",
    routes: [
        
        {
            path: "/",
            props: true,
            component: () =>
                import ("./components/map/mapPage.vue"),
            meta: {
                requiresAuth: false,
            },
        },
        {
            path: "/addlocation",
            props: true,
            component: () =>
                import ("./components/map/addLocation.vue"),
            meta: {
                requiresAuth: false,
            },
        },
        {
            path: "/addbigideas",
            props: true,
            component: () =>
                import ("./components/map/addBigideas.vue"),
            meta: {
                requiresAuth: false,
            },
        },
        // {
        //     path: "/test",
        //     props: true,
        //     component: () =>
        //         import ("./components/HelloWorld.vue"),
        //     meta: {
        //         requiresAuth: false,
        //     },
        // },
        {
            path: "/ServerError",
            name: "ServerError",
            props: true,
            component: () =>
                import ("./components/common/internalServer"),
            meta: {
                requiresAuth: false,
            },
        },
        {
            path: "*",
            name: "404PageNotFound",
            props: true,
            component: () =>
                import ("./components/common/pageNotfound"),
            meta: {
                requiresAuth: false,
            },
        },
    ],
    scrollBehavior() {
        document.body.scrollTop = 0; 
        document.documentElement.scrollTop = 0;
    },
});

export default router;