<template>
  <div id="appNavbar">
    <v-navigation-drawer v-model="sideNav" fixed temporary color="#005f32">
      <v-layout wrap justify-center>
        <v-flex
          xs12
          v-for="(item, i) in appNavItems"
          :key="i"
          text-center
          pa-2
          pl-0
          text-none
          align-self-center
        >
          <v-layout wrap justify-center>
            <v-flex xs12 px-0 pl-2 align-self-center text-left>
              <!-- <router-link :to="item.route"> -->
              <v-icon>{{ item.icon }}</v-icon>
              <span
                v-if="item.name == 'Research'"
                :style="
                  $route.path == item.route
                    ? {
                        'font-family': 'TitBold',
                      }
                    : {
                        'font-family': 'TitLight',
                      }
                "
                style="
                  font-size: 15px;
                  color: black;
                  letter-spacing: 3px;
                  cursor: pointer;
                "
              >
                <!-- {{ item.name }} -->
                <!-- <v-menu offset-y>
                  <template v-slot:activator="{ on, attrs }">
                    <v-layout
                      justify-start
                      color="#86ab5ef3"
                      v-bind="attrs"
                      v-on="on"
                    > -->
                <span
                  :style="
                    $route.path == item.route
                      ? {
                          'font-family': 'TitBold',
                        }
                      : {
                          'font-family': 'TitLight',
                        }
                  "
                  style="
                    font-size: 15px;
                    color: black;
                    letter-spacing: 3px;
                    cursor: pointer;
                  "
                >
                  {{ item.name }}
                </span>
                <!-- </v-layout>
                  </template> -->
                <v-list>
                  <v-list-item v-for="(item, index) in dropdown" :key="index">
                    <v-list-item-title>
                      <v-card width="200px">
                        <span
                          :style="
                            $route.path == item.route
                              ? {
                                  'font-family': 'TitBold',
                                }
                              : {
                                  'font-family': 'TitLight',
                                }
                          "
                          style="
                            font-size: 15px;
                            color: black;
                            letter-spacing: 3px;
                            cursor: pointer;
                          "
                          >{{ item.title }}</span
                        >
                      </v-card>
                    </v-list-item-title>
                  </v-list-item>
                </v-list>
                <!-- </v-menu> -->
              </span>
              <span v-else-if="item.name == 'Get Involved'">
                <!-- <v-menu offset-y>
                  <template v-slot:activator="{ on, attrs }">
                    <v-layout
                      justify-start
                      color="#86ab5ef3"
                      v-bind="attrs"
                      v-on="on"
                    > -->
                <span
                  :style="
                    $route.path == item.route
                      ? {
                          'font-family': 'TitBold',
                        }
                      : {
                          'font-family': 'TitLight',
                        }
                  "
                  style="
                    font-size: 15px;
                    color: black;
                    letter-spacing: 3px;
                    cursor: pointer;
                  "
                >
                  {{ item.name }}
                </span>
                <!-- </v-layout>
                  </template> -->
                <v-list>
                  <v-list-item v-for="(item, index) in drop" :key="index">
                    <v-list-item-title>
                      <v-card width="200px">
                        <router-link :to="item.route">
                          <span
                            :style="
                              $route.path == item.route
                                ? {
                                    'font-family': 'TitBold',
                                  }
                                : {
                                    'font-family': 'TitLight',
                                  }
                            "
                            style="
                              font-size: 15px;
                              color: black;
                              letter-spacing: 3px;
                              cursor: pointer;
                            "
                            >{{ item.title }}</span
                          >
                        </router-link>
                      </v-card>
                    </v-list-item-title>
                  </v-list-item>
                </v-list>
                <!-- </v-menu> -->
              </span>
              <a v-else :href="item.route">
                <span
                  :style="
                    $route.path == item.route
                      ? {
                          'font-family': 'TitBold',
                        }
                      : {
                          'font-family': 'TitLight',
                        }
                  "
                  style="
                    font-size: 15px;
                    color: white;
                    letter-spacing: 3px;
                    cursor: pointer;
                  "
                >
                  {{ item.name }}
                </span>
              </a>
              <!-- </router-link> -->
            </v-flex>
          </v-layout>
        </v-flex>
      </v-layout>
    </v-navigation-drawer>

    <v-app-bar
      app
      dark
      color="#005f32"
      dense
      flat
      :height="
        $vuetify.breakpoint.name == 'sm' || $vuetify.breakpoint.name == 'md'
          ? '70px'
          : '100px'
      "
      class="hidden-lg-and-up"
    >
      <v-toolbar-title>
        <router-link to="https://www.wti.org.in/">
          <v-layout wrap justify-start>
            <v-flex xs5 pa-2 sm5 md5 lg10 xl1 align-self-center text-left>
              <a class="routerlink" href="https://www.wti.org.in/">
                <v-img
                  contain
                  height="90px"
                  :src="require('./../../../public/wtilogo.png')"
                ></v-img>
              </a>
            </v-flex>
          </v-layout>
        </router-link>
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <v-btn icon class="mr-8" @click="toggleSideNav">
  <div style="padding: 7px; background-color: #000">
    <v-icon large color="#ffffff">mdi-menu</v-icon>
  </div>
</v-btn>
    </v-app-bar>
    <!-- <v-app-bar
      color="#FEBD08"
      elevation="0"
      height="70px"
      class="hidden-md-and-down"
    >
      <v-layout wrap justify-center fill-height>
        <v-flex xs2 align-self-center>
          <router-link to="/">
            <span
              style="
                font-family: TitBold;
                font-size: 20px;
                cursor: pointer;
                color: #FFFFFF;
              "
            >
              TIGERTHON
            </span>
          </router-link>
        </v-flex>
        <v-spacer></v-spacer>
      </v-layout>
    </v-app-bar> -->
  </div>
</template>
<script>
export default {
  components: {
  },
  data() {
    return {
      sideNav: false,
      navItems: [
        // { name: "MAP", route: "https://maps.wti.org.in/" },
        // { name: "OUR WORK", route: "https://www.wti.org.in/" },
        // { name: "WHY US", route: "https://www.wti.org.in/" },
        // { name: "RESOURCE CENTRE", route: "https://www.wti.org.in/" },
        // { name: "SHOP", route: "https://www.wti.org.in/" },
        // { name: "CONTACT US", route: "https://www.wti.org.in/" },
        // { name: "JOBS", route: "/" },
        // {name:"DONATE",route:'https://donation.wti.org.in/'}
        { name: "ABOUT US", route: "https://www.wti.org.in/our-story/" },
        { name: "OUR WORK", route: "https://www.wti.org.in/our-projects/" },
        // { name: "WHY US", route: "https://www.wti.org.in/" },
        { name: "MEDIA CENTRE", route: "https://mediaportal.wti.org.in/" },
        { name: "MAPS", route: "https://maps.wti.org.in/" },
        { name: "JOBS", route: "https://jobs.wti.org.in/" },
        // { name: "SHOP", route: "https://www.wti.org.in/" },
        { name: "CONTACT US", route: "https://www.wti.org.in/contact-us/" },
        {name:"DONATE",route:'https://donation.wti.org.in/'}
      ],
      // dropdown: [
      //   {
      //     title: "Publications",
      //   },
      //   {
      //     title: "Projects",
      //   },
      // ],
      // drop: [
      //   {
      //     title: "Volunteer",
      //     route: "/Volunteer",
      //   },
      //   {
      //     title: "Donate",
      //     route: "/About",
      //   },
      //   {
      //     title: "Membership",
      //     route: "/membership",
      //   },
      // ],
    };
  },
  computed: {
    appNavItems() {
      return this.navItems;
    },
  },
  methods: {
    toggleSideNav() {
      this.sideNav = !this.sideNav; 
    },
  },
};
</script>

<style>
input::-webkit-input-placeholder {
  color: #c5c5c5 !important;
  font-size: 14px !important;
  font-family: TitR !important;
}
input::-moz-placeholder {
  color: #c5c5c5 !important;
  font-size: 14px !important;
  font-family: TitR !important;
}
.searchBox .v-input__control {
  min-height: 10px !important;
}
.searchBox .v-input__control .v-input__slot {
  padding: 0px !important;
}
</style>
