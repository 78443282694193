import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import router from './router'
import axios from 'axios'
import VueElementLoading from "vue-element-loading";
import { VueEditor  } from "vue2-editor";
Vue.config.productionTip = false

Vue.use(VueEditor);


window.axios = require('axios')
// axios.defaults.baseURL = 'http://192.168.52.121:5000'
// Vue.prototype.baseURL = "http://192.168.52.121:5000"

// window.axios = require('axios')
axios.defaults.baseURL = 'https://wtiadmin.in'
Vue.prototype.baseURL = "https://wtiadmin.in"

Vue.component("VueElementLoading", VueElementLoading);

new Vue({
  router,
  vuetify,
  render: h => h(App)
}).$mount('#app')
