<template>
  <div>
    <!-- <PageLoader v-bind:storage="appLoading" /> -->
    <ServerError v-if="ServerError" />
    <v-app id="app">
      <v-snackbar
        v-model="showSnackBar"
        color="#FF1313"
        right
        :timeout="timeout"
      >
        <v-layout wrap justify-center>
          <v-flex text-left class="align-self-center">{{ msg }}</v-flex>
          <v-flex text-right>
            <v-btn small :ripple="false" text @click="showSnackBar = false">
              <v-icon style="color: white">mdi-close</v-icon>
            </v-btn>
          </v-flex>
        </v-layout>
      </v-snackbar>
      <v-divider></v-divider>
      <appHeader />
      <appHeader0/>
      <router-view
        :style="
          $vuetify.breakpoint.name == 'md'
            ? 'padding-top:50px'
            : $vuetify.breakpoint.name == 'xs'
            ? 'padding-top:0px'
            : 
            $vuetify.breakpoint.name == 'sm'
            ? 'padding-top:0px'
            :''
        "
        style="min-height: 90vh"
        :key="$route.fullPath"
        v-bind:storage="sideNav"
      />
    </v-app>
  </div>
</template>
<script>
import appHeader from './components/common/appHeader.vue';
import appHeader0 from './components/common/appHeader0.vue';

import "./assets/styles/fonts.css";
import "./assets/styles/appStyle.css";
export default {
  components: {
    appHeader,
    appHeader0

  },
  data() {
    return {
      sideNav: false,
      appLoading: false,
      ServerError: false,
      showSnackBar: false,
      msg: null,
      timeout: 5000,
      styloopData: {},
      queryPopUpDialog: false,
    };
  },

  beforeMount() {},
  methods: {},
};
</script>




















<!-- <template>
  <v-app>
    <v-main>
      <appHeader/>
    </v-main>
  </v-app>
</template>

<script>
import appHeader from './components/common/appHeader.vue';

export default {
  name: 'App',

  components: {
    appHeader
  },

  data: () => ({
    //
  }),
};
</script> -->
